import React, { Component } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import './editor.scss';

class DraftEditor extends Component {
    constructor(props) {
        super(props);
        const html = props.value || '<p></p>';
        const contentBlock = htmlToDraft(html);

        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.state = {
                editorState: editorState,
                htmlContent: html
            };
        }
    }

    onEditorStateChange = editorState => {
        if (this.props.getData) {
            this.props.getData(draftToHtml(convertToRaw(editorState.getCurrentContent())));
            this.setState({
                editorState,
                htmlContent: draftToHtml(convertToRaw(editorState.getCurrentContent()))
            });
        }
    };

    onTextAreaChange = e => {
        const contentBlock = htmlToDraft(e.target.value);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);

        this.props.getData(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        this.setState({
            editorState,
            htmlContent: e.target.value
        });
    };

    render() {
        const { editorState } = this.state;

        return (
            <div>
                <Editor
                    toolbarHidden={this.props.editorToolbarHidden}
                    editorState={editorState}
                    readOnly={this.props.disabled ? this.props.disabled : false}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    toolbarCustomButtons={this.props.toolbarCustom ? [this.props.toolbarCustom] : null}
                    onEditorStateChange={this.onEditorStateChange}
                />
                {this.props.editorToolbarHidden ? null : this.props.editorToolbarHiddenTextarea ? null : <textarea disable={true} value={this.state.htmlContent} onChange={this.onTextAreaChange} />}
            </div>
        );
    }
}

export default DraftEditor;
