import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { InputLabel, MuiThemeProvider, Typography } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';

// import './react-select.scss';

const theme = createTheme({
    overrides: {
        MuiButton: {
            raisedPrimary: {
                color: 'white'
            }
        }
    },
    typography: {
        fontSize: 11,
        useNextVariants: true,
        color: 'white'
    }
});

const MultiSelect = props => {
    const { selectOptions, selectedOptions, placeholder, name, label, customLabel, getMultiSelectData } = props;

    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState([]);

    //Setting the initial state of multi select
    useEffect(() => {
        setOptions(selectOptions);
        setSelected(selectOptions ? selectOptions.filter(option => (selectedOptions ? selectedOptions.includes(option.value) : false)) : []);
    }, [selectOptions, selectedOptions]);

    //Returning array of numbers as well as array of objects
    const handleChange = option => {
        if (!option) {
            option = [];
        }

        if (props.maxElements && option.length > props.maxElements) {
            return null;
        }

        const ids = [];
        const data = [];

        option.forEach(opt => {
            ids.push(opt.value);
            data.push({ label: opt.label, value: opt.value });
        });

        getMultiSelectData(ids, data);
        setSelected(option);
    };

    return (
        <div>
            {customLabel ? (
                <div
                    style={{
                        marginBottom: 5,
                        marginTop: 5,
                        textAlign: 'left'
                    }}
                >
                    <MuiThemeProvider theme={theme}>
                        <InputLabel disabled={false}>
                            <Typography>{customLabel}</Typography>
                        </InputLabel>
                    </MuiThemeProvider>
                </div>
            ) : null}

            <Select
                id={customLabel ? customLabel : label}
                {...props}
                name={name}
                placeholder={placeholder}
                options={options}
                value={selected}
                onChange={handleChange}
            />
        </div>
    );
};

export default MultiSelect;
