import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'cropperjs';
import './image_editor.scss';

import { Tooltip, IconButton } from '@material-ui/core';

import Crop from '@material-ui/icons/Crop';
import Reset from '@material-ui/icons/Restore';
import RotateLeft from '@material-ui/icons/RotateLeft';
import RotateRight from '@material-ui/icons/RotateRight';
import ArrowUp from '@material-ui/icons/ArrowUpward';
import ArrowDown from '@material-ui/icons/ArrowDownward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';
// import SettingsBackupRestore from '@material-ui/icons/SettingsBackupRestore';



const optionProps = [
    'dragMode',
    'aspectRatio',
    'data',
    'crop',
    // unchangeable props start from here
    'viewMode',
    'preview',
    'responsive',
    'restore',
    'checkCrossOrigin',
    'checkOrientation',
    'modal',
    'guides',
    'center',
    'highlight',
    'background',
    'autoCrop',
    'autoCropArea',
    'movable',
    'rotatable',
    'scalable',
    'zoomable',
    'zoomOnTouch',
    'zoomOnWheel',
    'wheelZoomRation',
    'cropBoxMovable',
    'cropBoxResizable',
    'toggleDragModeOnDblclick',
    'minContainerWidth',
    'minContainerHeight',
    'minCanvasWidth',
    'minCanvasHeight',
    'minCropBoxWidth',
    'minCropBoxHeight',
    'ready',
    'cropstart',
    'cropmove',
    'cropend',
    'zoom',
    'responseType'
];

const unchangeableProps = optionProps.slice(4);

class ImageEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rotateAngle: 0,
            image: '',
            isButtonDisabled: false
        };
        this.rotateToLeft = this.rotateToLeft.bind(this);
        this.rotateToRight = this.rotateToRight.bind(this);
        this.disableButton = this.disableButton.bind(this);

        this.crop = this.crop.bind(this);
    }
    componentDidMount() {
        const options = Object.keys(this.props)
            .filter(propKey => optionProps.indexOf(propKey) !== -1)
            .reduce((prevOptions, propKey) => Object.assign({}, prevOptions, { [propKey]: this.props[propKey] }), {});
        this.cropper = new Cropper(this.img, options);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.src !== this.props.src) {
            this.cropper
                .reset()
                .clear()
                .replace(nextProps.src);
        }
        if (nextProps.aspectRatio !== this.props.aspectRatio) {
            this.setAspectRatio(nextProps.aspectRatio);
        }
        if (nextProps.data !== this.props.data) {
            this.setData(nextProps.data);
        }
        if (nextProps.dragMode !== this.props.dragMode) {
            this.setDragMode(nextProps.dragMode);
        }
        if (nextProps.cropBoxData !== this.props.cropBoxData) {
            this.setCropBoxData(nextProps.cropBoxData);
        }
        if (nextProps.canvasData !== this.props.canvasData) {
            this.setCanvasData(nextProps.canvasData);
        }
        if (nextProps.moveTo !== this.props.moveTo) {
            if (nextProps.moveTo.length > 1) {
                this.moveTo(nextProps.moveTo[0], nextProps.moveTo[1]);
            } else {
                this.moveTo(nextProps.moveTo[0]);
            }
        }
        if (nextProps.zoomTo !== this.props.zoomTo) {
            this.zoomTo(nextProps.zoomTo);
        }
        if (nextProps.scaleX !== this.props.scaleX) {
            this.scaleX(nextProps.scaleX);
        }
        if (nextProps.scaleY !== this.props.scaleY) {
            this.scaleY(nextProps.scaleY);
        }
        if (nextProps.enable !== this.props.enable) {
            if (nextProps.enable) {
                this.enable();
            } else {
                this.disable();
            }
        }

        Object.keys(nextProps).forEach(propKey => {
            let isDifferentVal = nextProps[propKey] !== this.props[propKey];
            const isUnchangeableProps = unchangeableProps.indexOf(propKey) !== -1;

            if (typeof nextProps[propKey] === 'function' && typeof this.props[propKey] === 'function') {
                isDifferentVal = nextProps[propKey].toString() !== this.props[propKey].toString();
            }

            if (isDifferentVal && isUnchangeableProps) {
                throw new Error(`prop: ${propKey} can't be change after componentDidMount`);
            }
        });
    }

    componentWillUnmount() {
        if (this.img) {
            // Destroy the cropper, this makes sure events such as resize are cleaned up and do not leak
            this.cropper.destroy();
            delete this.img;
            delete this.cropper;
        }
    }

    setDragMode(mode) {
        return this.cropper.setDragMode(mode);
    }

    setAspectRatio(aspectRatio) {
        return this.cropper.setAspectRatio(aspectRatio);
    }

    getCroppedCanvas(options) {
        return this.cropper.getCroppedCanvas(options);
    }

    setCropBoxData(data) {
        return this.cropper.setCropBoxData(data);
    }

    getCropBoxData() {
        return this.cropper.getCropBoxData();
    }

    setCanvasData(data) {
        return this.cropper.setCanvasData(data);
    }

    getCanvasData() {
        return this.cropper.getCanvasData();
    }

    getImageData() {
        return this.cropper.getImageData();
    }

    getContainerData() {
        return this.cropper.getContainerData();
    }

    setData(data) {
        return this.cropper.setData(data);
    }

    getData(rounded) {
        return this.cropper.getData(rounded);
    }

    crop(options, cb, dis) {
        this.setState(
            {
                image: this.cropper.getCroppedCanvas(options)
            },
            cb, dis()
        );
    }
    disableButton() {

        this.setState(
            {
                isButtonDisabled: true
            },
        )
        setTimeout(() => this.setState({ isButtonDisabled: false }), 1000);
        return;
    }
    move(offsetX, offsetY) {
        return this.cropper.move(offsetX, offsetY);
    }

    moveTo(x, y) {
        return this.cropper.moveTo(x, y);
    }

    zoom(ratio) {
        return this.cropper.zoom(ratio);
    }

    zoomTo(ratio) {
        return this.cropper.zoomTo(ratio);
    }

    rotate(degree) {
        return this.cropper.rotate(degree);
    }

    rotateToLeft() {
        this.setState({ rotateAngle: this.state.rotateAngle - 30 });
        return this.cropper.rotateTo(this.state.rotateAngle - 30);
    }
    rotateToRight() {
        this.setState({ rotateAngle: this.state.rotateAngle + 30 });
        return this.cropper.rotateTo(this.state.rotateAngle + 30);
    }

    enable() {
        return this.cropper.enable();
    }

    disable() {
        return this.cropper.disable();
    }

    reset() {
        return this.cropper.reset();
    }

    clear() {
        return this.cropper.clear();
    }

    replace(url, onlyColorChanged) {
        return this.cropper.replace(url, onlyColorChanged);
    }

    scale(scaleX, scaleY) {
        return this.cropper.scale(scaleX, scaleY);
    }

    scaleX(scaleX) {
        return this.cropper.scaleX(scaleX);
    }

    scaleY(scaleY) {
        return this.cropper.scaleY(scaleY);
    }

    saveImage() {
        switch (this.props.responseType) {
            case 'blob':
                this.state.image.toBlob(blob => {
                    this.props.saveImage(blob);
                });
                break;
            case 'base64':
                this.props.saveImage(this.state.image.toDataURL());
                break;
            default:
                this.props.saveImage(this.state.image.toDataURL());
        }
    }

    render() {
        const { src, alt, crossOrigin, moveRight, moveLeft, moveDown, moveUp, zoomIn, zoomOut, rotatable, restart, zoomable, moovable } = this.props;

        return (
            <div src={null} alt={null} style={this.props.style} className={this.props.className}>
                <img
                    crossOrigin={crossOrigin}
                    ref={img => {
                        this.img = img;
                    }}
                    src={src}
                    alt={alt === undefined ? 'picture' : alt}
                    style={{ opacity: 0 }}
                />
                <br />
                {zoomable ? (
                    <span>
                        <Tooltip title={'Zoom in'}>
                            <IconButton onClick={() => this.zoom(zoomIn ? zoomIn : 0.1)}>
                                <ZoomIn fontSize="large" color="secondary" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Zoom out'}>
                            <IconButton onClick={() => this.zoom(zoomOut ? -zoomOut : -0.1)}>
                                <ZoomOut fontSize="large" color="secondary" />
                            </IconButton>
                        </Tooltip>
                    </span>
                ) : null}

                {moovable ? (
                    <span>
                        <Tooltip title={'Move left'}>
                            <IconButton onClick={() => this.move(moveLeft ? -moveLeft : -5, 0)}>
                                <ArrowBack fontSize="large" color="secondary" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Move right'}>
                            <IconButton onClick={() => this.move(moveRight ? moveRight : 5, 0)}>
                                <ArrowForward fontSize="large" color="secondary" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Move up'}>
                            <IconButton onClick={() => this.move(0, moveUp ? -moveUp : -5)}>
                                <ArrowUp fontSize="large" color="secondary" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Move down'}>
                            <IconButton onClick={() => this.move(0, moveDown ? moveDown : 5)}>
                                <ArrowDown fontSize="large" color="secondary" />
                            </IconButton>
                        </Tooltip>
                    </span>
                ) : null}

                {rotatable ? (
                    <span>
                        <Tooltip title={'Rotate left'}>
                            <IconButton onClick={() => this.rotateToLeft()}>
                                <RotateLeft fontSize="large" color="secondary" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Rotate right'}>
                            <IconButton onClick={() => this.rotateToRight()}>
                                <RotateRight fontSize="large" color="secondary" />
                            </IconButton>
                        </Tooltip>
                    </span>
                ) : null}
                <div>
                    <Tooltip title={'Reset'}>
                        <IconButton onClick={() => restart()}>
                            <Reset fontSize="large" color="secondary" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'Crop'}>
                        <IconButton disabled={this.state.isButtonDisabled} onClick={() => this.crop(this.props, this.saveImage, this.disableButton)}>
                            <Crop fontSize="large" color="secondary" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        );
    }
}

ImageEditor.propTypes = {
    style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    className: PropTypes.string,

    // react cropper options
    crossOrigin: PropTypes.string,
    imageName: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,

    // props of option can be changed after componentDidmount
    aspectRatio: PropTypes.number,
    dragMode: PropTypes.oneOf(['crop', 'move', 'none']),
    data: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
        rotate: PropTypes.number,
        scaleX: PropTypes.number,
        scaleY: PropTypes.number
    }),
    scaleX: PropTypes.number,
    scaleY: PropTypes.number,
    enable: PropTypes.bool,
    cropBoxData: PropTypes.shape({
        left: PropTypes.number,
        top: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number
    }),
    canvasData: PropTypes.shape({
        left: PropTypes.number,
        top: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number
    }),
    zoomTo: PropTypes.number,
    moveTo: PropTypes.arrayOf(PropTypes.number),
    rotateTo: PropTypes.number,

    // cropperjs options
    // https://github.com/fengyuanchen/cropperjs#options
    // aspectRatio, dragMode, data
    viewMode: PropTypes.oneOf([0, 1, 2, 3]),
    preview: PropTypes.string,
    responsive: PropTypes.bool,
    restore: PropTypes.bool,
    checkCrossOrigin: PropTypes.bool,
    checkOrientation: PropTypes.bool,
    modal: PropTypes.bool,
    guides: PropTypes.bool,
    center: PropTypes.bool,
    highlight: PropTypes.bool,
    background: PropTypes.bool,
    autoCrop: PropTypes.bool,
    autoCropArea: PropTypes.number,
    movable: PropTypes.bool,
    rotatable: PropTypes.bool,
    scalable: PropTypes.bool,
    zoomable: PropTypes.bool,
    zoomOnTouch: PropTypes.bool,
    zoomOnWheel: PropTypes.bool,
    wheelZoomRation: PropTypes.number,
    cropBoxMovable: PropTypes.bool,
    cropBoxResizable: PropTypes.bool,
    toggleDragModeOnDblclick: PropTypes.bool,
    minContainerWidth: PropTypes.number,
    minContainerHeight: PropTypes.number,
    minCanvasWidth: PropTypes.number,
    minCanvasHeight: PropTypes.number,
    minCropBoxWidth: PropTypes.number,
    minCropBoxHeight: PropTypes.number,
    ready: PropTypes.func,
    cropstart: PropTypes.func,
    cropmove: PropTypes.func,
    cropend: PropTypes.func,
    crop: PropTypes.func,
    zoom: PropTypes.func,
    saveImage: PropTypes.func,
    responseType: PropTypes.string
};

ImageEditor.defaultProps = {
    src: null,
    dragMode: 'crop',
    data: null,
    scaleX: 1,
    scaleY: 1,
    enable: true,
    zoomTo: 1,
    rotateTo: 0,
    imageName: 'New'
};

export default ImageEditor;
